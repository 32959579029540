<template>
    <div class="card-producto d-middle m-2 p-1" :class="{'border-general':info.promocion && info.data_promocion.borde}" @click="$emit('accion',info)">
        <div class="position-relative">
            <img class="br-10" height="90" width="90" :src="info.producto.imagen" alt="" />
            <p v-show="pedido.impuestos === 2 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
            <p v-show="pedido.impuestos === 1 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center"> + IVA</p>
        </div>
        <div class="col px-2">
            <div v-if="info.desconocido === 0" class="">
                <p class="nombre lh-18 f-500 text-general">{{ info.producto.nombre }}</p>
                <p class="text-general2">{{ info.producto.presentacion }}</p>
                <div class="row mx-0 align-items-center pb-1 text-general justify-content-between">
                    <span class="f-600">{{ convertMoneyTendero(valor_producto, pedido.idm_moneda) }}</span>
                    <p>
                        <b>{{ agregarSeparadoresNumero(info.cantidad,decimalFraccion) }}</b>
                        {{ info.producto.unidad }}
                    </p>
                </div>
                <div class="row mx-0">
                    <div v-if="info.promocion" class="bg-general br-12 px-2 tres-puntos text-white f-12">
                        <span>{{ info.data_promocion.texto }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="">
                <p class="nombre lh-18 f-600">Producto Desconocido</p>
                <p>{{ info.producto.nombre }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        diseno: {
            type: Object,
            default: () => { return { } }
        },
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    presentacion:  '',
                    precio: 0,
                }
            }
        }
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            colores: [ '', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ '', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ '', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],

        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_historial_tendero/pedido',
        }),
        impuesto(){
            const i = this.info.porcentaje_impuesto
            return i ? i : 0
        },
        valor_producto(){
            if(this.pedido.impuestos === 0){
                return this.info.total_final
            }
            if(this.pedido.impuestos === 1){
                return this.info.total_final
            }
            if(this.pedido.impuestos === 2 || this.pedido.impuestos === 3){
                return this.info.total_final * (1 + this.impuesto / 100)
            }
            return 0
        },
        decimalFraccion(){
            if(this.info.producto.cantidad_tipo === 1) return 0
            else return 2
        },
    },
}
</script>

<style lang="css" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
